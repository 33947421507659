import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, Text, TextInput } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentRenameMutation } from '../queries.generated';
import './index.scss';

interface IRenameDocumentDialog {
  documentId: string;
  currentName: string;
  onClose: () => void;
}

const BASE_CLASS = 'adb-rename-document';
const id = 'rename-document-modal';

const RenameDocumentDialog = ({
  documentId,
  currentName,
  onClose,
}: IRenameDocumentDialog) => {
  const [name, setName] = useState(currentName);

  const { t } = useTranslation();
  const { addError, addSuccess } = useNotificationContext();
  const { closeModal } = useModal();

  const notChanged = currentName === name;

  const onError = () => {
    addError({
      label: t('customer.documents.notification.error_title'),
      message: t('customer.documents.notification.rename_document_error_msg'),
    });
  };

  const [renameDocument, { loading }] = useDocumentRenameMutation({
    variables: {
      input: {
        id: documentId,
        name,
      },
    },

    onCompleted: ({ documentRename }) => {
      if (documentRename.status === 200) {
        addSuccess({
          label: t(
            'customer.documents.notification.rename_document_success_label'
          ),
          message: '',
        });
        closeModal();
        onClose();
      } else {
        onError();
      }
    },
    onError,
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = () => {
    renameDocument();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <AdbDialog id={id} onClose={onClose} className={BASE_CLASS}>
      <AdbDialog.Header>
        <Text variant="hl-100">{t('form_fields.documents.rename_title')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <TextInput
          id={`${BASE_CLASS}__input`}
          label={t('form_fields.documents.rename_input')}
          value={name}
          onChange={onChange}
          disabled={loading}
          maxLength={128}
          autoFocus
          minLength={1}
        />
        <div className={`${BASE_CLASS}__actions`}>
          <Button variant="secondary" onClick={onCancel} disabled={loading}>
            {t('feature_calendar.general.buttons.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={loading || notChanged}
            loading={loading}
          >
            <Button.Spinner />
            {t('feature_calendar.general.buttons.confirm')}
          </Button>
        </div>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default RenameDocumentDialog;
