import {
  DocumentCategory,
  DocumentStatus,
  DocumentType,
} from '@smart/adb-shared';
import SearchFilterPanel from '@smart/components-adb/atoms/SearchFilterPanel/SearchFilterPanel';
import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import { FilterProp } from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown.config';
import { Button, IconButton, Tag, TextInput } from '@smart/react-components';
import { getFormattedFiltersWithDisplayName } from '@ui/library/helpers/filters';
import { useCustomerContext } from 'contexts/customer-context';
import { useTranslation } from 'react-i18next';
import {
  DocumentFilters,
  DocumentsSearchProps,
  FilterTypeValue,
} from './Search.config';
import './Search.scss';

const BASE_CLASS = 'adb-document-search';

const mapFilters = (
  query: FilterTypeValue[] | undefined,
  type: DocumentFilters
) =>
  (query &&
    [...query].find(
      (item) =>
        item.type === type && {
          displayName: item.displayName.toLowerCase(),
          queryValue: item.queryValue.toLowerCase(),
          type: item.type,
        }
    )) ??
  undefined;

const DocumentsSearch = ({
  showFilters,
  searchQuery,
  filterQuery,
  isLoadingPurchasedProducts,
  isLoadingAppointments,
  toggleShowFilter,
  toggleCreateDocumentOpen,
  toggleUploadDocumentOpen,
  handleFilterQuery,
  handleSearchQuery,
  reloadDocuments,
  bulkDownloadDocuments,
  disableBulkDownload,
  isLoadingDocs,
}: DocumentsSearchProps) => {
  const { t } = useTranslation();

  const { customerIsLoading } = useCustomerContext();

  const categoryFilter = mapFilters(filterQuery, DocumentFilters.CATEGORY);
  const statusFilter = mapFilters(filterQuery, DocumentFilters.STATUS);
  const typeFilter = mapFilters(filterQuery, DocumentFilters.TYPE);

  const formattedDocumentCategoryFilters =
    getFormattedFiltersWithDisplayName(DocumentCategory);

  const formattedDocumentStatusFilters =
    getFormattedFiltersWithDisplayName(DocumentStatus);

  const formattedDocumentTypeFilters =
    getFormattedFiltersWithDisplayName(DocumentType);

  const getTranslatedFilterItems = (
    filterData: FilterProp[],
    filterType: 'category' | 'status' | 'type'
  ) => {
    switch (filterType) {
      case 'category':
        filterData.map((item: FilterProp) => {
          const documentCategory = Object.keys(DocumentCategory).find(
            (key: string) => (DocumentCategory as any)[key] === item.queryValue
          );

          if (documentCategory) {
            item.displayName = t(
              `customer.documents.table.category.${documentCategory?.toLowerCase()}`
            );
          }

          return item;
        });
        break;

      case 'status':
        filterData.map((item) => {
          const documentStatus = Object.keys(DocumentStatus).find(
            (key: string) => (DocumentStatus as any)[key] === item.queryValue
          );

          if (documentStatus) {
            item.displayName = t(
              `customer.documents.table.status.${documentStatus?.toLowerCase()}`
            );
          }

          return item;
        });
        break;

      case 'type':
        filterData.map((item) => {
          const documentType = Object.keys(DocumentType).find(
            (key: string) => (DocumentType as any)[key] === item.queryValue
          );

          if (documentType) {
            item.displayName = t(
              `customer.documents.table.type.${documentType?.toLowerCase()}`
            );
          }

          return item;
        });
        break;

      default:
        break;
    }
    return filterData;
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchQuery(event.target.value);
  };

  return (
    <>
      <SearchFilterPanel
        searchBar={
          <TextInput
            id="table_search"
            value={searchQuery}
            onChange={onSearch}
            label={t('customer.documents.search')}
            type="text"
          />
        }
        actionBtns={
          <>
            <Button
              variant="secondary"
              mode={200}
              type="button"
              onClick={toggleCreateDocumentOpen}
              className={`${BASE_CLASS}__btn-text`}
              disabled={isLoadingAppointments}
              loading={isLoadingAppointments}
            >
              <Button.Spinner />
              {t('customer.documents.buttons.create_document')}
            </Button>

            <Button
              variant="secondary"
              mode={200}
              type="button"
              onClick={bulkDownloadDocuments}
              className={`${BASE_CLASS}__btn-text`}
              disabled={
                disableBulkDownload || customerIsLoading || isLoadingDocs
              }
              loading={disableBulkDownload}
            >
              <Button.Spinner />
              {t('customer.documents.buttons.download')}
            </Button>

            <Button
              variant="secondary"
              mode={200}
              type="button"
              onClick={toggleUploadDocumentOpen}
              className={`${BASE_CLASS}__btn-text`}
              disabled={isLoadingPurchasedProducts || customerIsLoading}
              loading={isLoadingPurchasedProducts}
            >
              <Button.Spinner />
              {t('customer.documents.buttons.upload_new_document')}
            </Button>

            <Button
              variant="ghost"
              mode={200}
              type="button"
              onClick={reloadDocuments}
              className={`${BASE_CLASS}__btn-text`}
              disabled={isLoadingDocs}
            >
              <Button.Icon icon="refresh" />
              {t('customer.documents.buttons.refresh')}
            </Button>

            <IconButton
              variant="secondary"
              mode={200}
              type="button"
              onClick={toggleCreateDocumentOpen}
              className={`${BASE_CLASS}__btn-icon`}
              aria-label="create document"
            >
              <Button.Icon icon="plus" aria-label="plus" />
            </IconButton>

            <IconButton
              variant="secondary"
              mode={200}
              aria-label="upload document"
              type="button"
              onClick={toggleUploadDocumentOpen}
              className={`${BASE_CLASS}__btn-icon`}
            >
              <Button.Icon icon="upload" aria-label="upload" />
            </IconButton>

            <IconButton
              variant="secondary"
              mode={200}
              aria-label="refresh document list"
              type="button"
              onClick={reloadDocuments}
              className={`${BASE_CLASS}__btn-icon`}
              disabled={isLoadingDocs}
            >
              <Button.Icon icon="refresh" aria-label="refresh" />
            </IconButton>
          </>
        }
        filters={
          <Button
            variant="ghost"
            mode={200}
            type="button"
            onClick={toggleShowFilter}
          >
            {showFilters
              ? t('customer.documents.buttons.hide_filter')
              : t('customer.documents.buttons.show_filter')}
            <Button.Icon icon="filter" />
          </Button>
        }
      />

      {showFilters && (
        <div className={BASE_CLASS}>
          <div className={`${BASE_CLASS}__filters`}>
            <FilterDropdown
              label={t('customer.documents.facets.category')}
              items={getTranslatedFilterItems(
                formattedDocumentCategoryFilters,
                'category'
              )}
              onSelect={(filter) =>
                handleFilterQuery(filter, DocumentFilters.CATEGORY)
              }
              selectedFilter={categoryFilter}
            />

            <FilterDropdown
              label={t('customer.documents.facets.status')}
              items={getTranslatedFilterItems(
                formattedDocumentStatusFilters,
                'status'
              )}
              onSelect={(filter) =>
                handleFilterQuery(filter, DocumentFilters.STATUS)
              }
              selectedFilter={statusFilter}
            />

            <FilterDropdown
              label={t('customer.documents.facets.type')}
              items={getTranslatedFilterItems(
                formattedDocumentTypeFilters,
                'type'
              )}
              onSelect={(filter) =>
                handleFilterQuery(filter, DocumentFilters.TYPE)
              }
              selectedFilter={typeFilter}
            />
          </div>

          {(categoryFilter || statusFilter || typeFilter) && (
            <div className={`${BASE_CLASS}__tags`}>
              {categoryFilter && (
                <div
                  className={`${BASE_CLASS}__tags-item`}
                  key={categoryFilter.type}
                >
                  <Tag
                    open
                    onOpenChange={() =>
                      handleFilterQuery(undefined, DocumentFilters.CATEGORY)
                    }
                    key={categoryFilter.displayName}
                  >
                    {`${t('customer.documents.facets.category')}: ${
                      categoryFilter.displayName
                    }`}
                    <Tag.Close
                      aria-label={`remove filter ${categoryFilter.displayName}`}
                    />
                  </Tag>
                </div>
              )}

              {statusFilter && (
                <div
                  className={`${BASE_CLASS}__tags-item`}
                  key={statusFilter.type}
                >
                  <Tag
                    open
                    onOpenChange={() =>
                      handleFilterQuery(undefined, DocumentFilters.STATUS)
                    }
                    key={statusFilter.displayName}
                  >
                    {`${t('customer.documents.facets.status')}: ${
                      statusFilter.displayName
                    }`}
                    <Tag.Close
                      aria-label={`remove filter ${statusFilter.displayName}`}
                    />
                  </Tag>
                </div>
              )}

              {typeFilter && (
                <div
                  className={`${BASE_CLASS}__tags-item`}
                  key={typeFilter.type}
                >
                  <Tag
                    open
                    onOpenChange={() =>
                      handleFilterQuery(undefined, DocumentFilters.TYPE)
                    }
                    key={typeFilter.displayName}
                  >
                    {`${t('customer.documents.facets.type')}: ${
                      typeFilter.displayName
                    }`}
                    <Tag.Close
                      aria-label={`remove filter ${typeFilter.displayName}`}
                    />
                  </Tag>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentsSearch;
