import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, Text } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { Trans, useTranslation } from 'react-i18next';
import { useDocumentDeleteMutation } from '../queries.generated';
import './index.scss';

type DeleteDocumentDialogProps = {
  documentId: string;
  documentName: string;
  onClose: () => void;
};

const BASE_CLASS = 'adb-delete-document';
const id = 'delete-document-modal';

const DeleteDocumentDialog = ({
  documentId,
  documentName,
  onClose,
}: DeleteDocumentDialogProps) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotificationContext();
  const { closeModal } = useModal();

  const onError = () => {
    addError({
      label: t('customer.documents.notification.error_title'),
      message: t('customer.documents.notification.delete_document_error_msg'),
    });
  };

  const [deleteDocument, { loading }] = useDocumentDeleteMutation({
    variables: {
      input: {
        id: documentId,
      },
    },
    onCompleted: ({ documentDelete }) => {
      if (documentDelete.status === 200) {
        addSuccess({
          label: t(
            'customer.documents.notification.delete_document_success_label'
          ),
          message: '',
        });
        closeModal();
        onClose();
      } else {
        onError();
      }
    },
    onError,
  });

  return (
    <AdbDialog id={id} onClose={onClose} className={BASE_CLASS}>
      <AdbDialog.Header>
        <Text variant="hl-100">{t('form_fields.documents.delete_title')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <span>
          <Trans
            i18nKey="form_fields.documents.delete_confirmation_msg"
            values={{ documentName }}
            components={{ bold: <strong /> }}
          />
        </span>
        <div className={`${BASE_CLASS}__actions`}>
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            disabled={loading}
          >
            {t('feature_calendar.general.buttons.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteDocument();
            }}
            disabled={loading}
            loading={loading}
          >
            <Button.Spinner />
            {t('feature_calendar.general.buttons.confirm')}
          </Button>
        </div>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default DeleteDocumentDialog;
