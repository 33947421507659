import DetailsAndTeam from './details-and-team';
import DemoCarsOrderStatus from './order-status';
import OutletCars from './outlet-cars';

export const outlet = [
  {
    path: '/outlet/outlet-cars',
    component: OutletCars,
    title: 'navigation.outlet.outlet_cars',
  },
  {
    path: '/outlet/order-status',
    component: DemoCarsOrderStatus,
    title: 'navigation.outlet.order_status',
  },
  {
    path: '/outlet/details-and-team',
    component: DetailsAndTeam,
    title: 'navigation.outlet.details_and_team',
  },
];
