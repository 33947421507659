import classNames from 'classnames';

import {
  ListEnhancerVariant,
  ListItemEnhancerTypes,
  ListItemProps,
  OrderedListProps,
  UnOrderedListProps,
} from './List.config';
import './List.scss';

const BASE_CLASS = 'adb-list';

/**
 * Start or End Enhancer will be returned based on input
 *
 * @param args ListItemEnhancerTypes
 * @returns ListItemEnhancer ReactNode
 */
const ListItemEnhancer = (args: ListItemEnhancerTypes) => {
  const { enhancerType, enhancer } = args;

  return (
    <span
      className={classNames(`${BASE_CLASS}__item__${enhancerType}-enhancer`)}
    >
      {enhancer}
    </span>
  );
};

/**
 * List items for ordered and unordered lists
 *
 * @exports
 * @param children string/html/react component
 * @returns <li> ReactNode
 */
const ListItem = ({
  preEnhancer,
  content,
  postEnhancer,
  additionalClasses,
}: ListItemProps) => (
  <li className={classNames(`${BASE_CLASS}__item`, additionalClasses)}>
    {preEnhancer &&
      ListItemEnhancer({
        enhancerType: ListEnhancerVariant.START,
        enhancer: preEnhancer,
      })}
    {content}
    {postEnhancer &&
      ListItemEnhancer({
        enhancerType: ListEnhancerVariant.END,
        enhancer: postEnhancer,
      })}
  </li>
);

/**
 * Unordered List component
 *
 * @exports
 * @param additionalClasses custom class
 * @param additionalProps dataset (data-list)
 * @returns <ul> ReactNode
 */
const UnOrderedList = ({ additionalClasses, children }: UnOrderedListProps) => (
  <ul className={classNames(BASE_CLASS, additionalClasses)}>{children}</ul>
);

/**
 * Ordered List component
 *
 * @exports
 * @param additionalClasses custom class
 * @param additionalProps dataset (data-list)
 * @returns <ol> ReactNode
 */
const List = ({ children, additionalClasses, listStyle }: OrderedListProps) => (
  <ol
    className={classNames(BASE_CLASS, additionalClasses, {
      [`${BASE_CLASS}--${listStyle}`]: listStyle,
    })}
  >
    {children}
  </ol>
);

export default List;
export { ListItem, UnOrderedList };
