import { AdbSelect } from '@components/adb-required-label/AdbSelect';
import { AdbTextInput } from '@components/adb-required-label/AdbTextInput';
import {
  AllAppointmentsInput,
  Appointment,
  AppointmentType,
  AssetType,
  CarFilter,
  CarStatus,
  Customer,
  CustomerPrivate,
  CustomerType,
  ExtendedCar,
} from '@smart/adb-shared';
import { AdbDatePicker } from '@smart/components-adb/adb-required-label/AdbDatePicker';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { TypeAhead } from '@smart/components-adb/molecules/TypeAhead/TypeAhead';
import { TypeAheadPropList } from '@smart/components-adb/molecules/TypeAhead/TypeAhead.config';
import {
  Button,
  Icon,
  IconButton,
  Spinner,
  Text,
  TextInput,
} from '@smart/react-components';
import {
  formatDateAndTime,
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { setHour } from '@ui/library/helpers/date-locale';
import { nonNullable } from '@ui/library/helpers/filters';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import {
  addNewAppointmentToGqlCache,
  updateAppointmentInGqlCache,
} from '@utils/helpers/gqlCacheHelper';
import { useAgentContext } from 'contexts/agent-context';
import { useCalendarContext } from 'contexts/calendar-context';
import { useLanguageContext } from 'contexts/language-context';
import { useNotificationContext } from 'contexts/notification-context';
import { addMinutes } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { useAllAppointmentsLazyQuery } from 'graphql/queries/appointments.generated';
import { useCarsQuery } from 'graphql/queries/cars.generated';
import { useAllCustomersQuery } from 'graphql/queries/customers.generated';
import { AllTasksDocument } from 'graphql/queries/tasks.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppointmentLazyQuery } from '../../../pages/tasks/tasks/queries.generated';
import { BASE_CLASS_CREATE_APPOINTMENT } from '../config';
import {
  AppointmentCarSelectOption,
  CreateAppointmentDialogProps,
  CreateAppointmentFormValues,
  isHandoverAppointment,
  isTestDriveAppointment,
} from './CreateAppointmentDialog.config';
import './CreateAppointmentDialog.scss';
import CreateAppointmentSchema from './CreateAppointmentSchema';
import { CreateLightAccountForm } from './CreateLightAccountForm';
import {
  useCreateAppointmentMutation,
  useRescheduleAppointmentMutation,
} from './queries.generated';

const CreateAppointmentDialog = ({
  initialData: formInitialData,
  appointment,
  calendarDateRange,
  setSelectedAppointment,
  taskId,
  onClose,
  initialShowLightReg = false,
}: CreateAppointmentDialogProps) => {
  const { t } = useTranslation();
  const { addError, addSuccess, addInformation, closeNotification } =
    useNotificationContext();
  const { locale } = useLanguageContext();
  const { closeModal } = useModal();

  const useLeadFeatures = useFeatureFlag({
    defaultValue: false,
    key: 'use-lead-features',
  });

  const { demoCars, hasFilterChanged, experts, outlet } = useCalendarContext();

  const isReschedule = Boolean(appointment?.id);

  const { agent: loggedInExpert } = useAgentContext();
  const createLightAccount =
    initialShowLightReg &&
    formInitialData.customerEmail &&
    formInitialData.customerFirstName &&
    formInitialData.customerLastName;

  const [appointmentType, setAppointmentType] = useState<AppointmentType>(
    formInitialData.appointmentType
  );

  const validationSchema = CreateAppointmentSchema(appointmentType);

  const [getAppointments] = useAllAppointmentsLazyQuery();

  const [showLightReg, setShowLightReg] = useState(initialShowLightReg);
  const [lightAccountCreated, setLightAccountCreated] = useState(false);

  const refetchQueries = [
    {
      query: AllTasksDocument,
      variables: {
        input: {
          outletId: outlet?.mcsId,
        },
      },
      skip: !outlet?.mcsId,
    },
  ];

  const { data: customers, loading: outletCustomersLoading } =
    useAllCustomersQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    });

  const getSelectedCustomer = useCallback(() => {
    if (appointment && appointment.customer) return appointment.customer;
    if (customers?.allCustomers.customers && formInitialData.customerId) {
      return customers.allCustomers.customers.find(
        (customer) => customer.uuid === formInitialData.customerId
      ) as Customer;
    }
    return undefined;
  }, [
    appointment,
    customers?.allCustomers.customers,
    formInitialData.customerId,
  ]);

  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(getSelectedCustomer());

  const selectSuggestion = useCallback(
    (suggestion: TypeAheadPropList): void => {
      if (customers?.allCustomers.customers) {
        const selection = customers.allCustomers.customers.find(
          (customer) => customer.uuid === suggestion.id
        );

        setSelectedCustomer(selection);
      }
    },
    [customers]
  );

  useEffect(() => {
    if (customers && formInitialData.customerId) {
      setSelectedCustomer(getSelectedCustomer());
      selectSuggestion({
        title: '',
        id: formInitialData.customerId,
      });
    }
  }, [customers, formInitialData, getSelectedCustomer, selectSuggestion]);

  const [getAppointment] = useAppointmentLazyQuery();

  const appointmentsInput: AllAppointmentsInput = {
    startDateTime: setHour(calendarDateRange[0], 6),
    endDateTime: setHour(calendarDateRange[1], 23),
    outletId: outlet?.bpId ?? '',
  };

  const [createAppointment, { loading: createLoading }] =
    useCreateAppointmentMutation({
      update: (cache, { data }) =>
        addNewAppointmentToGqlCache({
          cache,
          appointmentId: data?.createAppointment.appointmentId,
          input: appointmentsInput,
          getAppointment,
        }),
      onCompleted: () => {
        addSuccess({
          label: t('feature_calendar.notification.create_appointment_title'),
          message: t(
            'feature_calendar.notification.create_appointment_description'
          ),
        });
        closeModal();
      },
      refetchQueries,
    });

  const [rescheduleAppointment, { loading: rescheduleLoading }] =
    useRescheduleAppointmentMutation({
      update: (cache, { data }) =>
        updateAppointmentInGqlCache({
          cache,
          appointmentId: data?.rescheduleAppointment.appointmentId,
          input: appointmentsInput,
          getAppointment,
        }),
      onCompleted: () => {
        addSuccess({
          label: t(
            'feature_calendar.notification.reschedule_appointment_title'
          ),
          message: t(
            'feature_calendar.notification.reschedule_appointment_description'
          ),
        });
        closeModal();
      },
      refetchQueries,
    });

  const initialData: CreateAppointmentFormValues = {
    appointmentType: formInitialData.appointmentType,
    customerName: `${selectedCustomer?.firstName ?? formInitialData.customerFirstName} ${selectedCustomer?.lastName ?? formInitialData.customerLastName}`,
    carId: formInitialData.carId,
    endDate: formInitialData.endDate,
    expertId: loggedInExpert.id,
    startDate: formInitialData.startDate,
    searchedCustomer: `${formInitialData.customerFirstName} ${formInitialData.customerLastName}`,
  };

  const [searchCustomerQuery, setSearchCustomerQuery] = useState<string>();

  const onChangeCustomerSearch = (value: string) => {
    setSearchCustomerQuery(value);
    setSelectedCustomer(undefined);
  };

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [alreadyBooked, setAlreadyBooked] = useState<boolean>(false);

  const demoCarOptions: AppointmentCarSelectOption[] = demoCars
    ?.map((car) => {
      if (
        car?.isInfleeted &&
        car?.licensePlateNumber &&
        car.status !== CarStatus.Inactive
      ) {
        return {
          label: car.licensePlateNumber,
          value: car.carId,
        };
      }

      return {
        label: '',
        value: '',
      };
    })
    .filter((car) => car.label.length);

  const expertSelectOptions: { label: string; value: string }[] =
    experts
      ?.map((expert) => {
        if (
          !expert.gigyaId ||
          expert.firstName === '' ||
          expert.lastName === ''
        ) {
          return null;
        }
        return {
          label: `${expert.firstName} ${expert.lastName}`,
          value: expert.gigyaId,
        };
      })
      .filter(nonNullable) ?? [];

  const {
    data,
    loading: purchasedProductsLoading,
    error: purchasedProductsError,
  } = useCarsQuery({
    variables: {
      input: {
        uuid: selectedCustomer?.uuid,
        type: AssetType.SellableVehicle,
      },
      filter: CarFilter.NonBooked,
    },
    skip:
      !selectedCustomer?.uuid || selectedCustomer.type === CustomerType.Lead,
    onError: (error) => {
      enhanceError({
        error,
        label: 'Failed to fetch customer products',
        displayMessage: error.message,
      });
    },
  });
  const customerAssets = data?.getCars.products;

  const purchasedCarOptions = useMemo(
    () =>
      customerAssets?.map((car: ExtendedCar) => ({
        label: car.orderNumber ?? '',
        value: car.vin,
      })),
    [customerAssets]
  );

  // setting message for empty customer suggestion list
  useEffect(() => {
    if (customers?.allCustomers.customers && searchCustomerQuery) {
      setErrorMessage(t('feature_calendar.general.no_result'));
    } else {
      setErrorMessage('');
    }
  }, [searchCustomerQuery, t, customers]);

  // get the deactivated text if the selected demoCar is inactive
  const getDemoCarDeactivatedText = (demoCarId: string) =>
    demoCars?.some(
      (demoCar) =>
        demoCar?.carId === demoCarId && demoCar?.status === CarStatus.Inactive
    ) ? (
      <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__deactivated-warning`}>
        <Icon
          className={`${BASE_CLASS_CREATE_APPOINTMENT}__deactivated-warning-icon`}
          icon="warning"
        />
        <Text
          className={`${BASE_CLASS_CREATE_APPOINTMENT}__deactivated-warning-text`}
          variant="cap-300"
        >
          {t('feature_calendar.error_messages.deactivated_democar')}
        </Text>
      </div>
    ) : null;

  // get the Info message if the selected handover vehicle is not infleted
  const getHandoverNonInfletedText = (carId: string) =>
    customerAssets &&
    customerAssets?.some(
      (customerAsset) =>
        customerAsset?.vin === carId &&
        (!customerAsset?.licensePlateNumber || !customerAsset?.registrationDate)
    ) ? (
      <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__deactivated-warning`}>
        <Icon
          className={`${BASE_CLASS_CREATE_APPOINTMENT}__deactivated-warning-icon`}
          icon="warning"
        />
        <Text
          className={`${BASE_CLASS_CREATE_APPOINTMENT}__deactivated-warning-text`}
          variant="cap-300"
        >
          {t('feature_calendar.error_messages.non_infleeted_text')}
        </Text>
      </div>
    ) : null;

  const isAppointmentAlreadyBooked = (orderNumber: string) => {
    getAppointments({
      variables: {
        input: {
          startDateTime: setHour(getDateXMonthsBefore(1), 6),
          endDateTime: setHour(getDateXMonthsAfter(1), 23),
          outletId: outlet?.bpId ?? '',
        },
      },
    })
      .then(({ data: appointmentData }) => {
        const existingAppointments =
          appointmentData?.allAppointments.appointments?.filter(
            (value) => value.car?.carId === orderNumber
          );
        if (!existingAppointments?.length) {
          setAlreadyBooked(false);
        } else {
          setAlreadyBooked(true);
          addInformation({
            label: t(
              'feature_calendar.notification.appointment_already_exist_title'
            ),
            message: (
              <div className="notification-content">
                <Text>
                  {t('feature_calendar.notification.appointment_already_exist')}
                </Text>
                <Text variant="lbl-100">
                  {t('feature_calendar.notification.existing_appointments')}
                </Text>
                {existingAppointments
                  ?.sort(
                    (a, b) =>
                      new Date(a.start).getTime() - new Date(b.start).getTime()
                  )
                  .map((appoint: Appointment) => (
                    <div key={appoint.id} className="appointment-info">
                      <div>
                        <Text variant="lbl-200">{t(appoint.title)}</Text>
                        <Text variant="p-100">
                          {t('feature_calendar.general.starts')}:{' '}
                          {formatDateAndTime(new Date(appoint.start), locale)}
                        </Text>
                        <Text variant="p-100">
                          {t('feature_calendar.general.ends')}:{' '}
                          {formatDateAndTime(new Date(appoint.end), locale)}
                        </Text>
                      </div>
                      <Button
                        type="button"
                        variant="primary"
                        mode={200}
                        onClick={() => {
                          closeNotification();
                          closeModal();
                          setSelectedAppointment?.({
                            ...appoint,
                            start: new Date(appoint.start),
                            end: new Date(appoint.end),
                          });
                        }}
                      >
                        {t('feature_calendar.notification.show_appointment')}
                      </Button>
                    </div>
                  ))}
              </div>
            ),
            persist: true,
          });
        }
      })
      .catch((error) => {
        addError({
          label: t('general.error_messages.something_went_wrong'),
          message: t(
            'feature_calendar.error_messages.check_if_appointment_exist'
          ),
          persist: true,
        });
        throw error;
      });
  };

  const handleFormSubmit = (formValues: CreateAppointmentFormValues) => {
    if (!selectedCustomer || !loggedInExpert) {
      return addError({
        label: 'Data missing',
        message: 'Logged in expert or selected customer is empty',
      });
    }
    if (!outlet?.bpId) {
      return addError({
        label: 'Data missing',
        message: "Couldn't find outlet id",
      });
    }

    if (appointment) {
      return rescheduleAppointment({
        variables: {
          input: {
            appointmentId: appointment.id,
            appointmentType: formValues.appointmentType,
            carId: formValues.carId,
            endDateTime: formValues.endDate,
            expertId: formValues.expertId,
            externalContactId: selectedCustomer.uuid ?? '',
            ...(selectedCustomer.__typename === 'CustomerBusiness' &&
              selectedCustomer?.bpid && {
                externalAccountId: selectedCustomer.bpid,
              }),
            outletId: outlet.bpId,
            startDateTime: formValues.startDate,
          },
        },
      });
    }

    return createAppointment({
      variables: {
        input: {
          externalContactId: selectedCustomer.uuid ?? '',
          ...(selectedCustomer.__typename === 'CustomerBusiness' &&
            selectedCustomer?.bpid && {
              externalAccountId: selectedCustomer.bpid,
            }),
          outletId: outlet.bpId,
          expertId: formValues.expertId ?? '',
          appointmentType: formValues.appointmentType,
          carId: formValues.carId,
          startDateTime: formValues.startDate,
          endDateTime: formValues.endDate,
          ...(taskId && { taskId }),
          ...(selectedCustomer.mobileNumber && {
            phone: selectedCustomer.mobileNumber,
          }),
          ...(outlet?.address?.postalCode &&
            !isTestDriveAppointment(formValues.appointmentType) && {
              postalCode: outlet.address.postalCode,
            }),
        },
      },
    });
  };

  const toggleLightReg = () => setShowLightReg(!showLightReg);

  const renderCustomerSelector = (touched: boolean) => {
    if (createLightAccount) {
      <TextInput
        value={[
          `${formInitialData.customerFirstName} ${formInitialData.customerLastName}`,
          formInitialData.customerEmail,
        ].join(' - ')}
        label={t('feature_calendar.calendar_dialog.search')}
        disabled
      />;
    }

    if (isReschedule || (formInitialData.customerId && selectedCustomer)) {
      const value = isReschedule
        ? [
            `${appointment?.customer?.firstName} ${appointment?.customer?.lastName}`,
            appointment?.customer?.userId,
          ]
        : [
            `${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`,
            selectedCustomer?.userId,
          ];
      return (
        <TextInput
          value={value.join(' - ')}
          label={t('feature_calendar.calendar_dialog.search')}
          disabled
        />
      );
    }
    if (lightAccountCreated && selectedCustomer) {
      return (
        <TextInput
          value={`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
          label={t('feature_calendar.calendar_dialog.search')}
          disabled
        />
      );
    }
    return (
      <>
        <TypeAhead
          list={customers?.allCustomers.customers.map((customer) => ({
            id: customer.uuid ?? undefined,
            title: `${customer?.firstName} ${customer?.lastName} - ${customer?.userId}`,
          }))}
          name="searchedCustomer"
          delay={400}
          onChange={onChangeCustomerSearch}
          onSelect={selectSuggestion}
          label={t('feature_calendar.calendar_dialog.search')}
          searchKey={searchCustomerQuery}
          errorMessageMinLength={t('general.labels.enter_3_char')}
          errorMessage={errorMessage}
          disabled={outletCustomersLoading}
          schema={validationSchema}
          minCharsToSearch={3}
        />
        {selectedCustomer && selectedCustomer.type === CustomerType.Lead && (
          <ErrorField
            errorMsg={t('feature_calendar.error_messages.customer_is_lead')}
          />
        )}
        {!selectedCustomer && touched && (
          <ErrorField
            errorMsg={t('feature_calendar.error_messages.select_customer')}
          />
        )}
      </>
    );
  };

  const dialogContent = (
    <Formik
      initialValues={initialData}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
      isResetForm
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
      }: FormikProps<CreateAppointmentFormValues>) => (
        <form autoComplete="on" onSubmit={handleSubmit}>
          <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__body`}>
            <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
              <Icon
                className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                icon="test-drive"
                mode={300}
              />
              <div
                className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
              >
                <AdbSelect
                  id="appointmentType"
                  name="appointmentType"
                  label={t('feature_calendar.calendar_dialog.appointment_type')}
                  options={[
                    {
                      label: t('feature_calendar.appointment_type.test_drive'),
                      value: AppointmentType.TestDrive,
                    },
                    // don't show handover option if demo car view is opened
                    ...(!hasFilterChanged
                      ? [
                          {
                            label: t(
                              'feature_calendar.appointment_type.handover'
                            ),
                            value: AppointmentType.Handover,
                          },
                        ]
                      : []),
                  ]}
                  value={values.appointmentType}
                  schema={validationSchema}
                  onChange={(e) => {
                    setAppointmentType(e.target.value as AppointmentType);
                    setFieldValue('appointmentType', e.target.value);
                  }}
                  disabled={isReschedule}
                />
                {errors.appointmentType && touched.appointmentType && (
                  <ErrorField errorMsg={errors.appointmentType} />
                )}
              </div>
            </div>

            {isHandoverAppointment(values.appointmentType) && (
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                {outletCustomersLoading && !createLightAccount ? (
                  <Spinner
                    size="md"
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-typeahead-loader`}
                  />
                ) : (
                  <Icon
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                    icon="account"
                    mode={300}
                  />
                )}
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  {renderCustomerSelector(Boolean(touched.searchedCustomer))}
                  {purchasedProductsLoading && (
                    <div
                      className={`${BASE_CLASS_CREATE_APPOINTMENT}__no-asset`}
                    >
                      <Spinner size="md" />
                      <Text
                        variant="cap-300"
                        className={`${BASE_CLASS_CREATE_APPOINTMENT}__no-asset-text`}
                      >
                        {t(
                          'feature_calendar.calendar_dialog.loading_asset_text'
                        )}
                      </Text>
                    </div>
                  )}
                  {purchasedProductsError && (
                    <div
                      className={`${BASE_CLASS_CREATE_APPOINTMENT}__no-asset`}
                    >
                      <Icon
                        className={`${BASE_CLASS_CREATE_APPOINTMENT}__empty-asset-icon`}
                        icon="error"
                      />
                      <Text
                        variant="cap-300"
                        className={`${BASE_CLASS_CREATE_APPOINTMENT}__no-asset-text`}
                      >
                        Failed to fetch customer products
                      </Text>
                    </div>
                  )}
                  {selectedCustomer && customerAssets?.length === 0 && (
                    <div
                      className={`${BASE_CLASS_CREATE_APPOINTMENT}__no-asset`}
                    >
                      <Icon
                        className={`${BASE_CLASS_CREATE_APPOINTMENT}__no-asset-icon`}
                        icon="info-legal"
                      />
                      <Text
                        variant="cap-300"
                        className={`${BASE_CLASS_CREATE_APPOINTMENT}__no-asset-text`}
                      >
                        {t('feature_calendar.info_messages.no_asset_found')}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            )}

            {isTestDriveAppointment(values.appointmentType) && (
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                {outletCustomersLoading && !createLightAccount ? (
                  <Spinner
                    size="md"
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-typeahead-loader`}
                  />
                ) : (
                  <Icon
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                    icon="account"
                    mode={300}
                  />
                )}
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  {renderCustomerSelector(Boolean(touched.searchedCustomer))}
                </div>
                {useLeadFeatures &&
                  !isReschedule &&
                  !lightAccountCreated &&
                  !(formInitialData.customerId && selectedCustomer) && (
                    <IconButton
                      aria-label={t('customer.register.new_light_customer')}
                      variant="secondary"
                      className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-light-button`}
                      type="button"
                      onClick={toggleLightReg}
                    >
                      <Button.Icon icon="plus" aria-label="plus" />
                    </IconButton>
                  )}
              </div>
            )}

            {isTestDriveAppointment(values.appointmentType) &&
              demoCarOptions && (
                <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                  <Icon
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                    icon="car"
                    mode={300}
                  />
                  <div
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                  >
                    <AdbSelect
                      id="carId"
                      name="carId"
                      label={t('feature_calendar.calendar_dialog.choose_car')}
                      options={[{ label: '', value: '' }, ...demoCarOptions]}
                      value={values.carId}
                      onChange={handleChange}
                      schema={validationSchema}
                    />
                    {values.carId && getDemoCarDeactivatedText(values.carId)}
                    {errors.carId && touched.carId && (
                      <ErrorField errorMsg={errors.carId} />
                    )}
                  </div>
                </div>
              )}

            {isHandoverAppointment(values.appointmentType) &&
              selectedCustomer &&
              customerAssets &&
              customerAssets?.length > 0 &&
              !initialData.carId &&
              purchasedCarOptions && (
                <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                  <Icon
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                    icon="car"
                    mode={300}
                  />
                  <div
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                  >
                    <AdbSelect
                      id="carId"
                      name="carId"
                      label={t('form_fields.documents.vehicle_order_number')}
                      options={[
                        { label: '', value: '' },
                        ...purchasedCarOptions,
                      ]}
                      value={values.carId}
                      onChange={(e) => {
                        handleChange(e);
                        isAppointmentAlreadyBooked(e.target.value);
                      }}
                      disabled={!!initialData.carId}
                      schema={validationSchema}
                    />
                    {values.carId && getHandoverNonInfletedText(values.carId)}
                    {errors.carId && touched.carId && (
                      <ErrorField errorMsg={errors.carId} />
                    )}
                    <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__info`}>
                      <Icon
                        className={`${BASE_CLASS_CREATE_APPOINTMENT}__info-legal-icon`}
                        icon="info-legal"
                      />
                      <Text
                        className={`${BASE_CLASS_CREATE_APPOINTMENT}__info-legal-text`}
                        variant="cap-300"
                      >
                        {t(
                          'feature_calendar.info_messages.correct_order_number'
                        )}
                      </Text>
                    </div>
                  </div>
                </div>
              )}

            {isHandoverAppointment(values.appointmentType) && (
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                <Icon
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                  icon="car"
                  mode={300}
                />
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  <AdbTextInput
                    id="carId"
                    name="carId"
                    disabled
                    value={values.carId}
                    label={t('feature_calendar.general.vin_number')}
                    schema={validationSchema}
                  />
                </div>
              </div>
            )}

            <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
              <Icon
                className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                icon="appointment"
                mode={300}
              />
              <div
                className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
              >
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-starttime-input`}
                >
                  <AdbDatePicker
                    withTime
                    id="startDate"
                    label={t('feature_calendar.general.starts')}
                    name="startDate"
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={(date: Date) => {
                      if (date) {
                        setFieldValue('startDate', date.toISOString());
                        setFieldValue(
                          'endDate',
                          addMinutes(date, 30).toISOString()
                        );
                      }
                    }}
                    selected={
                      (values.startDate && new Date(values.startDate)) || null
                    }
                    schema={validationSchema}
                  />
                  {errors.startDate && touched.startDate && (
                    <ErrorField errorMsg={errors.startDate} />
                  )}
                </div>
              </div>
            </div>

            <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
              <Icon
                className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-hidden-icon`}
                icon="appointment"
                mode={300}
              />
              <div
                className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
              >
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-endtime-input`}
                >
                  <AdbDatePicker
                    withTime
                    id="endDate"
                    label={t('feature_calendar.general.ends')}
                    name="endDate"
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={(date: Date) =>
                      date && setFieldValue('endDate', date.toISOString())
                    }
                    minDate={new Date(values.startDate)}
                    selected={
                      (values.endDate && new Date(values.endDate)) || null
                    }
                    schema={validationSchema}
                  />
                  {errors.endDate && touched.endDate && (
                    <ErrorField errorMsg={errors.endDate} />
                  )}
                </div>
              </div>
            </div>

            {expertSelectOptions && (
              <>
                <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                  <Icon
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                    icon="contact"
                    mode={300}
                  />
                  <div
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                  >
                    <AdbSelect
                      id="expertId"
                      name="expertId"
                      label={t('feature_calendar.general.smart_expert')}
                      options={[...expertSelectOptions]}
                      value={values.expertId}
                      onChange={handleChange}
                      schema={validationSchema}
                    />
                    {errors.expertId && touched.expertId && (
                      <ErrorField errorMsg={errors.expertId} />
                    )}
                  </div>
                </div>

                <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                  <Icon
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                    icon="dealer-locator-location"
                    mode={300}
                  />
                  <Text variant="p-200">{outlet && outlet.name}</Text>
                </div>
              </>
            )}
          </div>
          {!showLightReg && (
            <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__footer`}>
              <Button
                mode={200}
                variant="secondary"
                onClick={() => {
                  onClose?.();
                  closeModal();
                }}
              >
                {t('feature_calendar.general.buttons.cancel')}
              </Button>
              {isReschedule ? (
                <Button
                  mode={200}
                  type="submit"
                  variant="primary"
                  loading={rescheduleLoading}
                >
                  <Button.Spinner />
                  {t('feature_calendar.general.buttons.confirm')}
                </Button>
              ) : (
                <Button
                  disabled={
                    selectedCustomer === undefined ||
                    (isHandoverAppointment(values.appointmentType) &&
                      !customerAssets?.length) ||
                    createLoading ||
                    alreadyBooked ||
                    selectedCustomer.type === CustomerType.Lead
                  }
                  mode={200}
                  type="submit"
                  variant="primary"
                  loading={createLoading}
                >
                  <Button.Spinner />
                  {t('feature_calendar.general.buttons.create')}
                </Button>
              )}
            </div>
          )}
        </form>
      )}
    </Formik>
  );

  const onCreateLightAccount = (
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
    mobileNumber: string
  ) => {
    const customer: CustomerPrivate = {
      firstName,
      lastName,
      userId: email,
      uuid,
      mobileNumber,
    };
    setSelectedCustomer(customer);
    setShowLightReg(false);
    setLightAccountCreated(true);
  };

  return (
    <AdbDialog
      id="create-appointment-dialog"
      onClose={() => {
        onClose?.();
        closeModal();
      }}
      size={showLightReg ? 'double-medium' : 'medium'}
    >
      <AdbDialog.Content>
        <div
          className={`${BASE_CLASS_CREATE_APPOINTMENT}__wrapper`}
          style={{ gridTemplateColumns: showLightReg ? '1fr 1fr' : '1fr' }}
        >
          {dialogContent}
          {showLightReg && (
            <CreateLightAccountForm
              {...(initialShowLightReg && {
                initialData: {
                  firstName: formInitialData.customerFirstName,
                  lastName: formInitialData.customerLastName,
                  email: formInitialData.customerEmail,
                  mobileNumber: formInitialData.customerPhoneNumber,
                },
              })}
              onClose={toggleLightReg}
              onCreated={onCreateLightAccount}
            />
          )}
        </div>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default CreateAppointmentDialog;
