import { useTranslation } from 'react-i18next';
import { boolean, object, string } from 'yup';

export default () => {
  const { t } = useTranslation();
  return object().shape({
    agentAssuranceCheck: boolean().isTrue(),
    idValidityCheck: boolean().isTrue(),
    imageResolutionCheck: boolean().isTrue(),
    residencyForeignPassportCheck: boolean().isTrue(),
    idType: string().required(
      t('general.error_messages.validations.is_required')
    ),
    nationality: string().required(
      t('general.error_messages.validations.is_required')
    ),
    idNumber: string().required(
      t('general.error_messages.validations.is_required')
    ),
    dateOfIssue: string().required(
      t('general.error_messages.validations.is_required')
    ),
    idIssuingAuthority: string().required(
      t('general.error_messages.validations.is_required')
    ),
    dateOfExpiry: string().required(
      t('general.error_messages.validations.is_required')
    ),
  });
};
