import { Select, SelectProps } from '@smart/web-components';
import { AdbSchemaProps, getFieldLabel } from './helper';

type AdbSelectProps = SelectProps & AdbSchemaProps;

export const AdbSelect = (props: AdbSelectProps) => {
  const { schema, name, label } = props;

  return <Select {...props} label={getFieldLabel(schema, name, label)} />;
};
