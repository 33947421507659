import { useFeatureFlag } from '@utils/configs/featureFlag';

export const useAgentDashboardNavigation = () => {
  const useLeadFeatures = useFeatureFlag({
    defaultValue: false,
    key: 'use-lead-features',
  });

  return [
    {
      title: 'navigation.dashboard.tasks',
      path: '/tasks',
      name: 'navigation.dashboard.tasks',
      order: 1,
      pageItems: useLeadFeatures
        ? []
        : [
            {
              path: '/tasks',
              headline: 'navigation.dashboard.all_tasks',
            },
            {
              path: '/tasks/requests',
              headline: 'navigation.tasks.requests',
            },
            {
              path: '/tasks/todos',
              headline: 'navigation.tasks.tasks',
            },
          ],
    },
    {
      title: 'navigation.dashboard.customers',
      path: '/customer',
      name: 'navigation.dashboard.customers',
      order: 3,
    },
    {
      title: 'navigation.dashboard.orders',
      path: '/orders',
      name: 'navigation.dashboard.orders',
      order: 4,
    },
    {
      title: 'navigation.dashboard.stock',
      path: '/stock',
      name: 'navigation.dashboard.stock',
      order: 5,
    },
    {
      title: 'navigation.dashboard.outlet',
      path: '/outlet/outlet-cars',
      name: 'navigation.dashboard.outlet',
      order: 6,
      pageItems: [
        {
          path: '/outlet/outlet-cars',
          headline: 'navigation.outlet.outlet_cars',
        },
        {
          path: '/outlet/order-status',
          headline: 'navigation.outlet.order_status',
        },
        {
          path: '/outlet/details-and-team',
          headline: 'navigation.outlet.details_and_team',
        },
      ],
    },
  ];
};
