import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Flex, Text, TextArea } from '@smart/react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendFeedbackMutation } from './feedback.generated';
import { RatingButton } from './FeedbackRating';

export const FeedbackDialog = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [sendFeedback, { data, loading }] = useSendFeedbackMutation();

  const disableSubmit =
    data?.sendFeedback.success === false || loading || rating === 0;

  useEffect(() => {
    if (!loading && data?.sendFeedback.success) {
      onClose();
    }
  }, [data?.sendFeedback.success, loading, onClose]);

  return (
    <AdbDialog
      id="feedback-modal"
      data-testid="feedback-modal"
      onClose={onClose}
      buttons={{
        primary: {
          label: t('general.labels.submit'),
          onClick: () =>
            sendFeedback({
              variables: {
                input: {
                  rating,
                  comment,
                },
              },
            }),
          isLoading: loading,
          disabled: disableSubmit,
        },
        secondary: {
          label: t('general.labels.cancel'),
          onClick: () => {
            onClose();
          },
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">{t('feedback.label')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <Flex direction="column" gap={300}>
          <Flex gap={50}>
            <RatingButton selected={rating > 0} onClick={() => setRating(1)} />
            <RatingButton selected={rating > 1} onClick={() => setRating(2)} />
            <RatingButton selected={rating > 2} onClick={() => setRating(3)} />
            <RatingButton selected={rating > 3} onClick={() => setRating(4)} />
            <RatingButton selected={rating > 4} onClick={() => setRating(5)} />
          </Flex>
          <TextArea
            label={t('form_fields.documents.comment')}
            defaultValue={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Flex>
      </AdbDialog.Content>
    </AdbDialog>
  );
};
