import classNames from 'classnames';

import { SearchFilterPanelProps } from './SearchFilterPanel.config';

import './SearchFilterPanel.scss';

const BASE_CLASS = 'adb-search-filter-panel';

const SearchFilterPanel = ({
  searchBar,
  filters,
  actionBtns,
  darkMode,
  showSearch = true,
}: SearchFilterPanelProps) => (
  <div
    className={classNames(`${BASE_CLASS}`, {
      [`${BASE_CLASS}--dark-theme`]: darkMode,
    })}
  >
    {showSearch && (
      <div className={`${BASE_CLASS}__search-actions`}>
        <div className={`${BASE_CLASS}__search`}>{searchBar && searchBar}</div>
        <div className={`${BASE_CLASS}__actions`}>
          {actionBtns && actionBtns}
        </div>
      </div>
    )}

    <div
      className={classNames({
        [`${BASE_CLASS}__filters`]: showSearch,
      })}
    >
      {filters && filters}
    </div>
  </div>
);
export default SearchFilterPanel;
