import { useLanguageContext } from 'contexts/language-context';
import {
  de,
  enGB,
  enUS,
  es,
  fr,
  frCH,
  it,
  nl,
  nlBE,
  pt,
  sv,
} from 'date-fns/locale';
import DatePicker, {
  DatePickerProps,
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';

import { TextInput } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import './AdbDatePicker.scss';
import { AdbSchemaProps, getFieldLabel } from './helper';

registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('de-DE', de);
registerLocale('de-CH', de);
registerLocale('es-ES', es);
registerLocale('fr-FR', fr);
registerLocale('fr-BE', fr);
registerLocale('fr-CH', frCH);
registerLocale('it-IT', it);
registerLocale('it-CH', it);
registerLocale('nl-NL', nl);
registerLocale('nl-BE', nlBE);
registerLocale('pt-PT', pt);
registerLocale('sv-SE', sv);

setDefaultLocale('de');

type AdbDatePickerProps = Omit<
  DatePickerProps,
  'selectsRange' | 'selectsMultiple'
> &
  AdbSchemaProps & {
    withTime?: boolean;
    label: string;
    id: string;
    message?: string;
  };

export const AdbDatePicker = ({
  withTime = false,
  schema,
  name,
  label,
  id,
  selectsRange,
  message,
  value,
  invalid,
  selectsMultiple,
  ...rest
}: AdbDatePickerProps) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();

  return (
    <DatePicker
      {...rest}
      {...(withTime && {
        showTimeSelect: true,
        timeCaption: t('feature_calendar.general.time'),
        timeIntervals: 30,
      })}
      customInput={
        <TextInput
          id={id}
          invalid={invalid}
          label={getFieldLabel(schema, name, label)}
          caption={message}
          value={value}
        />
      }
      locale={locale ?? 'de-DE'}
    />
  );
};
