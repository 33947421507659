import { AssetType, Document, ExtendedCar } from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import type { FilterProp } from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown.config';
import { useModal } from '@smart/components-adb/molecules/Modal';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { useAgentContext } from 'contexts/agent-context';
import { useCustomerContext } from 'contexts/customer-context';
import { useMarketContext } from 'contexts/market-context';
import { useNotificationContext } from 'contexts/notification-context';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import { enhanceError } from 'graphql/reactive-error';
import CustomerLayout from 'layouts/customer-layout/CustomerLayout';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { filterNonServiceAppointments } from 'pages/tasks/config';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CreateDocumentType from './CreateDocumentType/CreateDocumentType';
import DeleteDocumentDialog from './DeleteDocument';
import { DocumentAction } from './Documents.config';
import './Documents.scss';
import RenameDocumentDialog from './RenameDocument';
import DocumentsSearch from './Search/Search';
import { DocumentFilters, FilterTypeValue } from './Search/Search.config';
import { useDocumentLazyQuery } from './ViewDocument/queries.generated';
import {
  useDocumentBulkDownloadMutation,
  useLoadDocumentsLazyQuery,
  usePurchasedProductsQuery,
} from './queries.generated';

const filterDocuments = (
  documents: Document[],
  searchQuery: string,
  filterQuery: FilterTypeValue[] | undefined,
  sortByNew: boolean = true
): Document[] => {
  let result: Document[] = [...documents];

  if (sortByNew && result.length > 0) {
    const filterWithoutDateModified = [...result].filter(
      (item) => !item.dateModified
    );

    const sortedDocuments =
      [...result]
        .filter((item) => item.dateModified)
        .sort(
          (firstItem, secondItem) =>
            new Date(secondItem.dateModified).getTime() -
            new Date(firstItem.dateModified).getTime()
        ) ?? [];

    if (sortedDocuments && sortedDocuments.length > 0) {
      result = [...sortedDocuments, ...filterWithoutDateModified];
    }
  }

  if (filterQuery && filterQuery?.length > 0 && result.length > 0) {
    result = result
      .filter((document) => {
        const filterTypeData = filterQuery.find(
          (item: any) => item.type === DocumentFilters.TYPE
        );

        if (filterTypeData && filterTypeData?.queryValue !== document?.type) {
          return false;
        }

        return document;
      })
      .filter((document) => {
        const filterTypeData = filterQuery.find(
          (item: any) => item.type === DocumentFilters.CATEGORY
        );

        if (
          filterTypeData &&
          filterTypeData?.queryValue !== document?.category
        ) {
          return false;
        }

        return document;
      })
      .filter((document) => {
        const filterTypeData = filterQuery.find(
          (item: any) => item.type === DocumentFilters.STATUS
        );

        if (filterTypeData && filterTypeData?.queryValue !== document?.status) {
          return false;
        }

        return document;
      });
  }

  if (searchQuery && searchQuery !== '' && result.length > 0) {
    result = result.filter(
      (document) =>
        document.name.toLowerCase().includes(searchQuery.toLowerCase()) ??
        document
    );
  }
  return result;
};

const UploadDocument = lazy(() => import('./UploadDocument/UploadDocument'));
const DocumentsList = lazy(() => import('./DocumentsList/DocumentsList'));
const ViewDocument = lazy(() => import('./ViewDocument/ViewDocument'));
const SignDocument = lazy(() => import('./SignDocument/SignDocument'));

const BASE_CLASS = 'adb-customer-document';

const Documents = () => {
  const { t } = useTranslation();
  const { registerModal } = useModal();
  const { customerId, bpid } = useParams();

  const { addError, addSuccess } = useNotificationContext();
  const { agent } = useAgentContext();
  const { market } = useMarketContext();
  const { customer } = useCustomerContext();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<FilterTypeValue[] | undefined>(
    undefined
  );

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [loadDocuments, { data, loading: docsLoading }] =
    useLoadDocumentsLazyQuery({
      fetchPolicy: 'network-only',
      onError: (errors) => {
        if (
          errors.message.includes('signal is aborted') ||
          errors.message.includes('aborted a request')
        ) {
          return;
        }
        addError({
          label: t('customer.documents.create.error'),
          message: t('customer.documents.create.load_document_error'),
        });
      },
    });

  const filteredDocuments = filterDocuments(
    data?.documentSearch.documents ?? [],
    searchQuery,
    filterQuery
  );

  const [downloadBulk, { loading: bulkDownloadIsLoading }] =
    useDocumentBulkDownloadMutation({
      variables: {
        input: {
          customerId: customer?.userId ?? '',
          documents: filteredDocuments.map((d) => String(d.id)),
        },
      },
    });

  const bulkDownload = async () => {
    const res = await downloadBulk();

    if (res.data?.documentBulkDownload?.signedUrl) {
      const alink = document.createElement('a');
      alink.href = res.data.documentBulkDownload.signedUrl;
      alink.download = t('customer.documents.bulk_filename');
      alink.click();
    }
  };

  const refreshDocumentsData = () => {
    loadDocuments({
      variables: {
        input: {
          ...(customerId && { customerId }),
          ...(customer?.userId && { userId: customer?.userId }),
        },
      },
    });
  };

  const { data: appointments, loading: isLoadingAppointments } =
    useAllAppointmentsQuery({
      variables: {
        input: {
          outletId: agent?.outletId ?? '',
          startDateTime: getDateXMonthsBefore(2).toISOString(),
          endDateTime: getDateXMonthsAfter(2).toISOString(),
          customerId: customer?.uuid,
        },
      },
      skip: !customer || !agent?.outletId,
    });

  useEffect(() => {
    refreshDocumentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, bpid]);

  // function to handle category filter query
  const handleFilterQuery = (
    filter: FilterProp | undefined,
    type: DocumentFilters
  ): void => {
    if (filter) {
      const filterValue: FilterTypeValue = {
        displayName: filter?.displayName,
        queryValue: filter?.queryValue,
        type,
      };

      // add the query to array
      if (filterQuery === undefined) {
        setFilterQuery([filterValue]);
        return;
      }

      // replace the existing query value with new values
      const duplicateFilter = [...filterQuery].filter(
        (item) => item.type === type
      );

      if (duplicateFilter.length > 0) {
        const queryToBeUpdated = [...filterQuery].map((item) =>
          item.type === type ? filterValue : item
        );

        setFilterQuery(queryToBeUpdated);
        return;
      }

      // add the new passed query to an array
      setFilterQuery([...filterQuery, filterValue]);
      return;
    }

    // removes the filter query
    setFilterQuery(
      filterQuery && [...filterQuery].filter((item) => item.type !== type)
    );
  };

  // function to handle search query
  const handleSearchQuery = (value: string): void => {
    setSearchQuery(value);
  };

  // function to toggle filters
  const toggleShowFilter = (): void => {
    setShowFilters(!showFilters);
  };

  const handleViewDocument = (document: Document) => {
    registerModal(
      <Suspense>
        <ViewDocument document={document} customerEmail={customer?.userId} />
      </Suspense>
    );
  };

  const [loadDocument, { loading: downloadingDoc }] = useDocumentLazyQuery();

  const handleDocumentActions = (
    document: Document,
    type: FilterProp | undefined
  ) => {
    switch (type?.queryValue) {
      case DocumentAction.SIGN: {
        registerModal(
          <Suspense>
            <SignDocument
              documentId={document.id}
              onClose={refreshDocumentsData}
            />
          </Suspense>
        );
        break;
      }
      case DocumentAction.DOWNLOAD: {
        loadDocument({
          variables: {
            input: {
              customerEmail: customer?.userId ?? '',
              id: document?.id ?? '',
            },
          },
          fetchPolicy: 'network-only',
          onCompleted: (d) => {
            const alink = window.document.createElement('a');
            alink.href = `data:${d.document.type};base64, ${d.document.file}`;
            alink.download = document.name;
            alink.click();

            addSuccess({
              label: t('customer.documents.create.success'),
              message: t(
                'customer.documents.notification.download_document_success'
              ),
            });
          },
          onError: () => {
            addError({
              label: t('customer.documents.notification.error_title'),
              message: t('customer.documents.notification.view_document_error'),
              persist: true,
            });
          },
        });
        break;
      }
      case DocumentAction.RENAME: {
        registerModal(
          <RenameDocumentDialog
            documentId={document.id}
            currentName={document.name}
            onClose={refreshDocumentsData}
          />
        );
        break;
      }

      case DocumentAction.DELETE: {
        registerModal(
          <DeleteDocumentDialog
            documentId={document.id}
            documentName={document.name}
            onClose={refreshDocumentsData}
          />
        );
        break;
      }

      default:
        break;
    }
  };

  const { data: getCars, loading: purchasedProductsLoading } =
    usePurchasedProductsQuery({
      variables: {
        input: {
          uuid: customerId ?? '',
          type: AssetType.SellableVehicle,
        },
      },
      onError: (error) => {
        enhanceError({
          error,
          label: 'Failed to fetch customer products',
          displayMessage: error.message,
        });
      },
      skip: !customerId,
    });

  const purchasedProducts = (getCars?.getCars.products ?? []) as ExtendedCar[];

  const customerAppointments = filterNonServiceAppointments(
    appointments?.allAppointments.appointments
  );

  const toggleCreateDocumentOpen = (): void => {
    if (!customer) return;
    registerModal(
      <CreateDocumentType
        selectedCustomerById={customer}
        appointments={customerAppointments}
        onCompleted={refreshDocumentsData}
      />
    );
  };

  const toggleUploadDocumentOpen = (): void => {
    if (!customer) return;
    registerModal(
      <Suspense fallback={<LoadingIndicator />}>
        <UploadDocument
          selectedCustomer={customer}
          selectedOutletMarket={market}
          purchasedProducts={purchasedProducts}
          onUploadedDocument={refreshDocumentsData}
        />
      </Suspense>
    );
  };

  return (
    <CustomerLayout>
      <div className={BASE_CLASS}>
        <AdbLayout.Header backgroundColor="bg-level-2">
          <DocumentsSearch
            showFilters={showFilters}
            searchQuery={searchQuery}
            filterQuery={filterQuery}
            toggleCreateDocumentOpen={toggleCreateDocumentOpen}
            toggleUploadDocumentOpen={toggleUploadDocumentOpen}
            toggleShowFilter={toggleShowFilter}
            handleFilterQuery={handleFilterQuery}
            handleSearchQuery={handleSearchQuery}
            reloadDocuments={refreshDocumentsData}
            isLoadingPurchasedProducts={purchasedProductsLoading}
            bulkDownloadDocuments={bulkDownload}
            disableBulkDownload={bulkDownloadIsLoading}
            isLoadingAppointments={isLoadingAppointments}
            isLoadingDocs={docsLoading}
          />
        </AdbLayout.Header>
        <AdbLayout.Content>
          <Suspense fallback={<LoadingIndicator />}>
            <DocumentsList
              loadingStatus={docsLoading}
              documentsListData={filteredDocuments}
              handleDocumentActions={handleDocumentActions}
              handleViewDocument={handleViewDocument}
            />
          </Suspense>
          {downloadingDoc && (
            <div className={`${BASE_CLASS}__fullscreen-loading`}>
              <LoadingIndicator />
            </div>
          )}
        </AdbLayout.Content>
      </div>
    </CustomerLayout>
  );
};
export default Documents;
