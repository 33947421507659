import { PropsWithChildren, ReactNode } from 'react';

/**
 * @enum ListEnhancerVariant
 * Accepted variants of list enhancer ListItemEnhancerTypes.enhancerType
 */
export enum ListEnhancerVariant {
  START = 'start',
  END = 'end',
}

/**
 * @enum ListStyles
 * Accepts list styles for <list>: OrderedListProps.listStyle
 */
export enum ListStyles {
  DEFAULT = 'default',
  LOWER_ALPHA = 'lower-alpha',
  UPPER_ALPHA = 'upper-alpha',
  LOWER_ROMAN = 'lower-roman',
  UPPER_ROMAN = 'upper-roman',
  NONE = 'none',
}

/**
 * @type ListProps
 * Default type for the list <List>|<UnOrderedList> props
 */
type ListProps = {
  additionalClasses?: string;
  additionalProps?: string;
} & PropsWithChildren;

/**
 * @type UnOrderedListProps
 * Prop type for unordered list <UnOrderedList>
 */
export type UnOrderedListProps = ListProps;

/**
 * @type OrderedListProps
 * Prop type for ordered list <List>
 */
export type OrderedListProps = ListProps & {
  listStyle?: ListStyles;
};

/**
 * @type ListItemProps
 * Prop type for <ListItem>
 */
export type ListItemProps = {
  content?: ReactNode;
  preEnhancer?: ReactNode;
  postEnhancer?: ReactNode;
  additionalClasses?: string;
};

/**
 * @type ListItemEnhancerTypes
 * Prop type for <ListItem> enhancers
 */
export type ListItemEnhancerTypes = {
  enhancerType: ListEnhancerVariant;
  enhancer: ReactNode;
};
