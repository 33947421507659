import { Select } from '@smart/web-components';
import type { SelectOptionProps } from 'features/features.config';
import { useTranslation } from 'react-i18next';

const TypeOfContractSelect = ({
  value,
  handleChange,
  options,
  disabled,
}: SelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Select
      id="typeOfContract"
      name="typeOfContract"
      label={t('form_fields.documents.type_of_document')}
      options={options ?? []}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default TypeOfContractSelect;
