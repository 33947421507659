import { Text } from '@smart/react-components';
import classNames from 'classnames';
import { ErrorFieldProps } from './ErrorField.config';

import './ErrorField.scss';

const BASE_CLASS = 'adb-ErrorField';

const ErrorField = ({
  errorMsg,
  hidden = false,
  additionalClasses,
}: ErrorFieldProps) => {
  if (!errorMsg || hidden) {
    return null;
  }

  return (
    <Text
      variant="cap-300"
      as="p"
      className={classNames(BASE_CLASS, additionalClasses)}
    >
      {errorMsg}
    </Text>
  );
};

export default ErrorField;
