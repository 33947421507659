import {
  AllAppointmentsInput,
  AssetType,
  CustomerAccountType,
  CustomerBusiness,
  RescheduleAppointmentInput,
} from '@smart/adb-shared';
import { AdbDatePicker } from '@smart/components-adb/adb-required-label/AdbDatePicker';
import CreateAppointmentSchema from '@smart/components-adb/calendar/CreateAppointmentDialog/CreateAppointmentSchema';
import { Button, Icon, Text, TextInput } from '@smart/react-components';
import { Select } from '@smart/web-components';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { nonNullable } from '@ui/library/helpers/filters';
import { updateAppointmentInGqlCache } from '@utils/helpers/gqlCacheHelper';
import { useAgentContext } from 'contexts/agent-context';
import { useNotificationContext } from 'contexts/notification-context';
import { addMinutes } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useRescheduleAppointmentMutation } from 'graphql/queries/appointments.generated';
import { useCarsQuery } from 'graphql/queries/cars.generated';
import { useOutletAgentsQuery } from 'graphql/queries/outlet.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useAppointmentLazyQuery } from 'pages/tasks/tasks/queries.generated';
import { useTranslation } from 'react-i18next';
import {
  RescheduleAppointmentFormProps,
  RescheduleAppointmentFormValues,
} from './RescheduleAppointmentForm.config';
import './RescheduleAppointmentForm.scss';

const BASE_CLASS = 'adb-non-confirmed-appointment';
const RescheduleAppointmentForm = ({
  appointment,
  onClose,
}: RescheduleAppointmentFormProps) => {
  const { t } = useTranslation();
  const outlet = useCurrentOutlet();
  const notification = useNotificationContext();
  const { agent } = useAgentContext();

  const { data: agentsData, loading: agentsLoading } = useOutletAgentsQuery({
    variables: {
      outletId: outlet?.mcsId ?? '',
    },
    skip: !outlet?.mcsId,
  });

  const [getAppointment] = useAppointmentLazyQuery();

  const appointmentsInput: AllAppointmentsInput = {
    endDateTime: getDateXMonthsAfter(6).toISOString(),
    outletId: outlet?.bpId ?? '',
    startDateTime: getDateXMonthsBefore(6).toISOString(),
  };

  const [rescheduleAppointment, { loading: isRescheduelling }] =
    useRescheduleAppointmentMutation({
      update: (cache, { data }) =>
        updateAppointmentInGqlCache({
          cache,
          appointmentId: data?.rescheduleAppointment.appointmentId,
          input: appointmentsInput,
          getAppointment,
        }),
      onCompleted: () => {
        notification.addSuccess({
          label: t(
            'feature_calendar.notification.reschedule_appointment_title'
          ),
          message: t(
            'feature_calendar.notification.reschedule_appointment_description'
          ),
        });
        onClose();
      },
      onError: (error) => {
        if (error.graphQLErrors[0]?.extensions?.code === 1) {
          enhanceError({
            error,
            level: 'warning',
            label: t('feature_calendar.notification.error_title'),
            displayMessage: t(
              'feature_calendar.notification.error_description_no_time_slots'
            ),
          });
        } else {
          enhanceError({
            error,
            label: t('feature_calendar.notification.error_title'),
            displayMessage: t(
              'feature_calendar.notification.error_description'
            ),
          });
        }
      },
    });

  const getExpertSelectOptions: { label: string; value: string }[] =
    agentsData?.outletAgents
      ?.map((expert) => {
        if (
          !expert.gigyaId ||
          expert.firstName === '' ||
          expert.lastName === ''
        ) {
          return null;
        }
        return {
          label: `${expert.firstName} ${expert.lastName}`,
          value: expert.gigyaId,
        };
      })
      .filter(nonNullable) ?? [];

  const defaultExpertId =
    getExpertSelectOptions.length > 0 ? getExpertSelectOptions[0]?.value : '';

  const initialValues: RescheduleAppointmentFormValues = {
    appointmentType: appointment.type?.replace('_', ' ') ?? '',
    customerName: [
      appointment.customer?.firstName,
      appointment.customer?.lastName,
    ].join(' '),
    carId: appointment.car?.carId ?? '',
    startDateTime: new Date(appointment.start).toISOString(),
    endDateTime: new Date(appointment.end).toISOString(),
    expertId: appointment.expert?.id ? appointment.expert.id : defaultExpertId,
  };

  const { data: purchasedProductsData } = useCarsQuery({
    variables: {
      input: {
        type: AssetType.DemoVehicle,
        bpid: outlet?.bpId,
      },
    },
  });

  const selectedDemoCars = purchasedProductsData?.getCars.products ?? [];
  const demoCarOptions = selectedDemoCars?.map((car) => {
    if (car.isInfleeted && car.licensePlateNumber) {
      return {
        label: car.licensePlateNumber,
        value: car.carId,
      };
    }
    return {
      label: '',
      value: '',
    };
  });

  const validationSchema = CreateAppointmentSchema();

  const handleFormSubmit = (data: RescheduleAppointmentFormValues) => {
    let input: RescheduleAppointmentInput = {
      appointmentId: appointment.id ?? '',
      appointmentType: data.appointmentType,
      carId: data.carId,
      endDateTime: data.endDateTime,
      expertId: data.expertId,
      externalContactId: appointment.customer?.uuid ?? '',
      outletId: outlet?.bpId ?? '',
      startDateTime: data.startDateTime,
    };
    if (appointment.customer?.accountType === CustomerAccountType.Business) {
      input = {
        ...input,
        externalAccountId: (appointment.customer as CustomerBusiness).bpid,
      };
    }
    rescheduleAppointment({
      variables: {
        input,
      },
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
      {(props: FormikProps<RescheduleAppointmentFormValues>) => {
        const { values, handleChange, handleSubmit, setFieldValue } = props;
        return (
          <Form autoComplete="on" onSubmit={handleSubmit}>
            <div className={`${BASE_CLASS}__reschedule`}>
              <Icon
                className={`${BASE_CLASS}__reschedule-icon`}
                icon="test-drive"
                mode={300}
              />
              <div className={`${BASE_CLASS}__reschedule-content`}>
                <TextInput
                  id="appointmentType"
                  name="appointmentType"
                  label={t('feature_calendar.calendar_dialog.appointment_type')}
                  value={values.appointmentType}
                  onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div className={`${BASE_CLASS}__reschedule`}>
              <Icon
                className={`${BASE_CLASS}__reschedule-icon`}
                icon="account"
                mode={300}
              />
              <div className={`${BASE_CLASS}__reschedule-content`}>
                <TextInput
                  id="customerName"
                  name="customerName"
                  disabled
                  value={values.customerName}
                  label={t('test_drive.summary.customer')}
                />
              </div>
            </div>
            <div className={`${BASE_CLASS}__reschedule`}>
              <Icon
                className={`${BASE_CLASS}__reschedule-icon`}
                icon="car"
                mode={300}
              />
              <div className={`${BASE_CLASS}__reschedule-content`}>
                <Select
                  id="carId"
                  name="carId"
                  label={t('feature_calendar.calendar_dialog.choose_car')}
                  options={
                    selectedDemoCars?.length === 0
                      ? [
                          {
                            label: appointment.car?.licensePlate ?? '',
                            value: appointment.car?.carId ?? '',
                          },
                        ]
                      : [...demoCarOptions]
                  }
                  value={values.carId}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={`${BASE_CLASS}__reschedule`}>
              <Icon
                className={`${BASE_CLASS}__reschedule-icon`}
                icon="appointment"
                mode={300}
              />
              <div className={`${BASE_CLASS}__reschedule-starttime`}>
                <div className={`${BASE_CLASS}__reschedule-starttime-input`}>
                  <AdbDatePicker
                    withTime
                    id="startDateTime"
                    label={t('feature_calendar.general.starts')}
                    name="startDateTime"
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={(date: Date) => {
                      if (date) {
                        setFieldValue('startDateTime', date.toISOString());
                        setFieldValue(
                          'endDateTime',
                          addMinutes(date, 30).toISOString()
                        );
                      }
                    }}
                    minDate={new Date(initialValues.startDateTime)}
                    selected={
                      (values.startDateTime &&
                        new Date(values.startDateTime)) ||
                      null
                    }
                    schema={validationSchema}
                    required
                  />
                </div>
              </div>
            </div>
            <div className={`${BASE_CLASS}__reschedule`}>
              <div className={`${BASE_CLASS}__reschedule-endtime`}>
                <div className={`${BASE_CLASS}__reschedule-endtime-input`}>
                  <AdbDatePicker
                    withTime
                    id="endDateTime"
                    label={t('feature_calendar.general.ends')}
                    name="endDateTime"
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={(date: Date) =>
                      date && setFieldValue('endDateTime', date.toISOString())
                    }
                    minDate={new Date(values.endDateTime)}
                    selected={
                      (values.endDateTime && new Date(values.endDateTime)) ||
                      null
                    }
                    schema={validationSchema}
                    required
                  />
                </div>
              </div>
            </div>
            <div className={`${BASE_CLASS}__reschedule`}>
              <Icon
                className={`${BASE_CLASS}__reschedule-icon`}
                icon="contact"
                mode={300}
              />
              <div className={`${BASE_CLASS}__reschedule-content`}>
                <Select
                  id="expertId"
                  name="expertId"
                  label={t('feature_calendar.general.smart_expert')}
                  options={
                    !agentsLoading && agentsData?.outletAgents?.length === 0
                      ? [
                          {
                            label: agent?.fullName ?? '',
                            value: agent?.id ?? '',
                          },
                        ]
                      : [...getExpertSelectOptions]
                  }
                  value={values.expertId}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={`${BASE_CLASS}__reschedule`}>
              <Icon
                className={`${BASE_CLASS}__reschedule-icon`}
                icon="dealer-locator-location"
                mode={300}
              />
              <Text variant="p-200">{outlet?.name ?? ''}</Text>
            </div>
            <div className={`${BASE_CLASS}__reschedule`}>
              <div className={`${BASE_CLASS}__reschedule-footer`}>
                <Button
                  type="submit"
                  variant="primary"
                  loading={isRescheduelling}
                  disabled={
                    values.expertId === '' ||
                    values.carId === '' ||
                    isRescheduelling
                  }
                >
                  <Button.Spinner />
                  {t('feature_calendar.general.buttons.confirm')}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default RescheduleAppointmentForm;
