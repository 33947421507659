import type { FilterProp } from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown.config';

/**
 * @type DocumentsSearchProps
 * Props for <DocumentsSearch /> component
 */
export type DocumentsSearchProps = {
  showFilters: boolean;
  searchQuery: string;
  filterQuery: FilterTypeValue[] | undefined;
  handleFilterQuery: (
    filter: FilterProp | undefined,
    type: DocumentFilters
  ) => void;
  handleSearchQuery: (value: string) => void;
  toggleCreateDocumentOpen: () => void;
  toggleShowFilter: () => void;
  toggleUploadDocumentOpen: () => void;
  reloadDocuments: () => void;
  isLoadingPurchasedProducts: boolean;
  bulkDownloadDocuments: () => void;
  disableBulkDownload: boolean;
  isLoadingAppointments: boolean;
  isLoadingDocs: boolean;
};

/**
 * @interface FilterTypeValue
 * FilterType values information
 */
export interface FilterTypeValue {
  displayName: string;
  queryValue: string;
  type:
    | DocumentFilters.CATEGORY
    | DocumentFilters.STATUS
    | DocumentFilters.TYPE;
}

/**
 * @enum DocumentFilters
 * filters for Documents
 */
export enum DocumentFilters {
  TYPE = 'TYPE',
  CATEGORY = 'CATEGORY',
  STATUS = 'STATUS',
}
